import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../components/CopyRight';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/Naqel.png';
const defaultTheme = createTheme();



export default function Login() {
    const navigate = useNavigate();



    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [gotOTP, setGotOTP] = React.useState(false);
    const [OTP, setOTP] = React.useState('');
    const getOTP = async() => {
        const response = await fetch('http://localhost:5000/user/generateOTP', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({phoneNumber: phoneNumber}),
        });
        const data = await response.json();
        console.log(data);
    }

    const Login = async() => {
        // const response = await fetch('http://localhost:5000/user/login', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({phoneNumber: phoneNumber, OTP: OTP}),
        // });
        // const data = await response.json();
        //console.log(data);
        navigate('/home');
        console.log('Navigated');

    }


    

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >

            <Box
              component="img"
              sx={{
                height: 63,
                width: 275,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
                mb:20
              }}
              alt="The house from the offer."
              src={logo}
            />

            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate  sx={{ mt: 1 }}>
            {
              !gotOTP && (
              <TextField
                margin="normal"
                required
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                name="phoneNumber"
                autoComplete="phoneNumber"
                autoFocus
              />
              )
            }

            {
                gotOTP && (
                    <TextField
                margin="normal"
                required
                fullWidth
                id="OTP"
                label="OTP"
                name="OTP"
              />
                )
            }
            

            {
                !gotOTP && (
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => {
                            setGotOTP(true);
                            getOTP();
                        }}
                    >
                Get OTP
              </Button>
                )
            }
            {
                gotOTP && (
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => {
                            Login();
                        }}
                        >
                        Login
                    </Button>
                )
            }
             
              
              <Grid container>
               {/* {
                !gotOTP && (
                    <Grid item>
                    <Link href="#" variant="body2">
                        {"Don't have an account? Sign Up"}
                    </Link>
                    </Grid>
                )
               } */}
                
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        
      </Grid>
    </ThemeProvider>
  );
}