import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from '../assets/menu';
import Toolbar from '@mui/material/Toolbar';

import Copyright from '../components/CopyRight';
import AppbarDrawer from '../components/AppbarDrawer';

import ReactPlayer from 'react-player';



// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


export default function Training() {
    const videoUrl = 'https://www.youtube.com/watch?v=JvEFw2AGLOw&ab_channel=AutoAuctionRebuilds';

    return (
        <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppbarDrawer title="Training"/>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Chart */}
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                   <Typography component="h1" variant="h4" color="#000" gutterBottom>
                        Current Training Status
                    </Typography>
                    <Divider />
                    

                    <Typography component="h1" variant="h5" color="#0F0" gutterBottom>
                        Complete
                    </Typography>
                </Paper>
              </Grid>
              {/* Recent Deposits */}
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 700
                  }}
                >
                  <ReactPlayer
          url={videoUrl}
          width="100%"
          height="100%"
          controls // Show video controls
        />
                </Paper>
              </Grid>
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
    )
}