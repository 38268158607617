import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from '../assets/menu';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';

import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';

import Copyright from '../components/CopyRight';
import AppbarDrawer from '../components/AppbarDrawer';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const defaultTheme = createTheme();

export default function Home() {
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
      setOpen(!open);
    };
  


    // Fields 

    // General information Fields
    const [firstNameEnglish, setFirstNameEnglish] = React.useState('');
    const [lastNameEnglish, setLastNameEnglish] = React.useState('');
    const [firstNameArabic, setFirstNameArabic] = React.useState('');
    const [lastNameArabic, setLastNameArabic] = React.useState('');
    const [city, setCity] = React.useState('');
    const [district, setDistrict] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [idNumber, setIdNumber] = React.useState('');
    const [idExpiryDate, setIdExpiryDate] = React.useState(null);
    const [isSaudi, setIsSaudi] = React.useState(false);
    const [nationality, setNationality] = React.useState('');

    // Register information Fields
    const [personalPhoto, setPersonalPhoto] = React.useState(null);
    const [idPhoto, setIdPhoto] = React.useState(null);
    const [driverLicensePhoto, setDriverLicensePhoto] = React.useState(null);
    const [carLicensePhoto, setCarLicensePhoto] = React.useState(null);
    const [insurancePhoto, setInsurancePhoto] = React.useState(null);
    const [bankIbanPhoto, setBankIbanPhoto] = React.useState('');

    // Vehicle information Fields
    const [carLicenseType, setCarLicenseType] = React.useState('');
    const [carType, setCarType] = React.useState('');
    const [carModel, setCarModel] = React.useState('');
    const [carYear, setCarYear] = React.useState('');
    const [carColor, setCarColor] = React.useState('');
    const [carPlateNumber, setCarPlateNumber] = React.useState('');
    const [carPhotoFront, setCarPhotoFront] = React.useState(null);
    const [carPhotoBack, setCarPhotoBack] = React.useState(null);



    return (
      <ThemeProvider theme={defaultTheme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppbarDrawer title="Profile"/>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: 10,
                    }}
                  >
                     <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="general-information-content"
          id="general-information-header"
        >
          <Typography component="h1" variant="h4" color="#000" gutterBottom>
            General Information
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {/* General Information Fields */}
            <Grid item xs={12} md={6}>
              <TextField
                label="First Name (English)"
                variant="outlined"
                fullWidth
                value={firstNameEnglish}
                onChange={(e) => setFirstNameEnglish(e.target.value)}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Last Name (English)"
                variant="outlined"
                fullWidth
                value={lastNameEnglish}
                onChange={(e) => setLastNameEnglish(e.target.value)}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="First Name (Arabic)"
                variant="outlined"
                fullWidth
                value={firstNameArabic}
                onChange={(e) => setFirstNameArabic(e.target.value)}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Last Name (Arabic)"
                variant="outlined"
                fullWidth
                value={lastNameArabic}
                onChange={(e) => setLastNameArabic(e.target.value)}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="City"
                variant="outlined"
                fullWidth
                value={city}
                onChange={(e) => setCity(e.target.value)}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="District"
                variant="outlined"
                fullWidth
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="ID Number"
                variant="outlined"
                fullWidth
                value={idNumber}
                onChange={(e) => setIdNumber(e.target.value)}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="ID Expiry Date"
                variant="outlined"
                fullWidth
                type="date"
                value={idExpiryDate}
                onChange={(e) => setIdExpiryDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Nationality"
                variant="outlined"
                fullWidth
                value={nationality}
                onChange={(e) => setNationality(e.target.value)}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isSaudi}
                    onChange={(e) => setIsSaudi(e.target.checked)}
                    disabled={true}
                  />
                }
                label="Is Saudi"
              />
            </Grid>
            {/* Add any other fields as needed */}
          </Grid>
        </AccordionDetails>
      </Accordion>
                  </Paper>
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={12} lg={12}>
                <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: 10,
                    }}
                  >
                   <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="register-information-content"
                            id="register-information-header"
                        >
                            <Typography component="h1" variant="h4" color="#000" gutterBottom>
                            Register Information
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3}>
                            {/* Register Information Fields */}
                            <Grid item xs={12} md={6}>
                                <InputLabel>Personal Photo</InputLabel>
                                <Input
                                type="file"
                                accept="image/*"
                                onChange={(e) => setPersonalPhoto(e.target.files[0])}
                                placeholder='Upload a photo of yourself for personal identification.'
                                disabled={true}
                                />
                                
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel>ID Photo</InputLabel>
                                <Input
                                type="file"
                                accept="image/*"
                                onChange={(e) => setIdPhoto(e.target.files[0])}
                                disabled={true}

                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel>Driver License Photo</InputLabel>
                                <Input
                                type="file"
                                accept="image/*"
                                onChange={(e) => setDriverLicensePhoto(e.target.files[0])}
                                disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel>Car License Photo</InputLabel>
                                <Input
                                type="file"
                                accept="image/*"
                                onChange={(e) => setCarLicensePhoto(e.target.files[0])}
                                disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel>Insurance Photo</InputLabel>
                                <Input
                                type="file"
                                accept="image/*"
                                onChange={(e) => setInsurancePhoto(e.target.files[0])}
                                disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel>Bank IBAN Photo</InputLabel>
                                <Input
                                type="file"
                                accept="image/*"
                                onChange={(e) => setBankIbanPhoto(e.target.files[0])}
                                disabled={true}
                                />
                            </Grid>
                            
                            {/* Add any other fields as needed */}
                            </Grid>
                        </AccordionDetails>
                        </Accordion>
                  </Paper>
                </Grid>
                {/* Recent Orders */}
                <Grid item xs={12} md={12} lg={12}>
                <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: 10,
                    }}
                  >
                   <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="vehicle-information-content"
              id="vehicle-information-header"
            >
              <Typography component="h1" variant="h4" color="#000" gutterBottom>
                Vehicle Information
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <InputLabel>Car License Type</InputLabel>
                  <TextField
                    label="Car License Type"
                    variant="outlined"
                    fullWidth
                    value={carLicenseType}
                    onChange={(e) => setCarLicenseType(e.target.value)}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>Car Type</InputLabel>
                  <TextField
                    label="Car Type"
                    variant="outlined"
                    fullWidth
                    value={carType}
                    onChange={(e) => setCarType(e.target.value)}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>Car Model</InputLabel>
                  <TextField
                    label="Car Model"
                    variant="outlined"
                    fullWidth
                    value={carModel}
                    onChange={(e) => setCarModel(e.target.value)}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>Car Year</InputLabel>
                  <TextField
                    label="Car Year"
                    variant="outlined"
                    fullWidth
                    value={carYear}
                    onChange={(e) => setCarYear(e.target.value)}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>Car Color</InputLabel>
                  <TextField
                    label="Car Color"
                    variant="outlined"
                    fullWidth
                    value={carColor}
                    onChange={(e) => setCarColor(e.target.value)}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>Car Plate Number</InputLabel>
                  <TextField
                    label="Car Plate Number"
                    variant="outlined"
                    fullWidth
                    value={carPlateNumber}
                    onChange={(e) => setCarPlateNumber(e.target.value)}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* Assuming you're using an input type=file for uploading images */}
                  <InputLabel>Car Photo Front</InputLabel>
                  <Input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setCarPhotoFront(e.target.files[0])}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {/* Assuming you're using an input type=file for uploading images */}
                  <InputLabel>Car Photo Back</InputLabel>
                  <Input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setCarPhotoBack(e.target.files[0])}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
                    
                  </Paper>
                </Grid>
              </Grid>
              <Copyright sx={{ pt: 4 }} />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }