import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from '../assets/menu';
import Toolbar from '@mui/material/Toolbar';
import { Button } from '@mui/material';
import Copyright from '../components/CopyRight';
import AppbarDrawer from '../components/AppbarDrawer';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


function createData(company, date, status, notes) {
    return { company, date, status, notes };
 }

const rows = [
createData('Uber', '16/10/2021', 'Approved', 'N/A'),
createData('Careem', '16/10/2021', 'Declined', 'Please Check your ID Expiry Date'),
createData('Jeeny', '16/10/2021', 'Approved', 'N/A'),
createData('Spark Ride', '16/10/2021', 'Approved', 'N/A'),
];
  

export default function Applications() {
    const [companyName, setCompanyName] = React.useState('');

    return (
        <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppbarDrawer title="Applications"/>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Current Application Status */}
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                    <Typography component="h1" variant="h4" color="#000" gutterBottom>
                        Current Application Status
                    </Typography>
                    <Divider />
                    <Typography component="h1" variant="h5" color="#000" gutterBottom>
                        UBER
                    </Typography>

                    <Typography component="h1" variant="h5" color="#0F0" gutterBottom>
                        Approved
                    </Typography>
                </Paper>
              </Grid>
              {/* Submit A New Application */}
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography component="h1" variant="h4" color="#000" gutterBottom>
                        Submit New Application 
                    </Typography>
                    <Divider />
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Company Name</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={companyName}
                            label="companyName"
                            onChange={e => setCompanyName(e.target.value)}
                            sx={{ mt: 0, mb: 2 }}
                        >
                            <MenuItem value={'SparkRide'}>Spark Ride</MenuItem>
                            <MenuItem value={'Uber'}>Uber</MenuItem>
                            <MenuItem value={'Careem'}>Careem</MenuItem>
                            <MenuItem value={'Jeeny'}>Jeeny</MenuItem>
                        </Select>
                        </FormControl>
                    <Button variant="outlined" color="primary">
                        Submit
                    </Button>
                </Paper>
              </Grid>
              {/* All Applications */}
              <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <Typography component="h1" variant="h4" color="#000" gutterBottom>
                        All Your Applications
                    </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Company Name</TableCell>
                            <TableCell >Date</TableCell>
                            <TableCell >Status</TableCell>
                            <TableCell >Notes</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows.map((row) => (
                            <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                                {row.company}
                            </TableCell>
                            <TableCell >{row.date}</TableCell>
                            <TableCell >{row.status}</TableCell>
                            <TableCell >{row.notes}</TableCell>

                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
    )
}