import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Applications from './pages/Applications';
import Training from './pages/Training';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/register" element={<Login/>}/>
        <Route path="/home" element={<Profile/>}/>
        <Route path="/profile" element={<Profile/>}/>
        <Route path="/applications" element={<Applications/>}/>
        <Route path="/training" element={<Training/>}/>
      </Routes>
    </Router>
  );
}

export default App;
